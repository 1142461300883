import { Spin } from 'antd';
import { Modal } from 'hera-ui';
import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, IndexRoute, Redirect, Route, Switch } from 'react-router-dom';
const Login = lazy(() => import('../Page/DownLogin/Login'));
const App = lazy(() => import('../layout/layoutCom'));
const Share = lazy(() => import('../wechatPage/Share'));
const Transfer = lazy(() => import('../wechatPage/empty'));
const Android = lazy(() => import('../wechatPage/android'));
const QuestionnaireLink = lazy(() => import('../SystemPage/Report/QuestionnaireLink'));
const SignaturePage = lazy(() => import('../SystemPage/Report/SignaturePage'));
const IntelligentReportDetailPDF = lazy(() => import('../SystemPage/DailyReport/IntelligentReportDetailPDF'));
const IntelligentReportDetailMobile = lazy(() => import('../SystemPage/DailyReport/IntelligentReportDetailMobile'));
const SearchResultPage = lazy(() => import('../SystemPage/SearchResult/SearchResultPage'));
const ActivityInvitation = lazy(() => import('../SystemPage/Report/ActivityInvitation'));
const ActiveEarnings = lazy(() => import('../SystemPage/Report/ActiveEarnings'));
const ActiveWithdrawal = lazy(() => import('../SystemPage/Report/ActiveWithdrawal'));
const PageGeneratePdf = lazy(() => import('SystemPage/AcademicPlan/lineLive/pageGeneratePdf'));
const InvitationLetter = lazy(() => import('SystemPage/AcademicPlan/InvitationLetter'));

/**
 * @title 不需要登录的页面
 * @description 多tabs的情况下，退出登录会重定向到登录页面，而不需要登录的页面，不需要这个操作
 */
export const noLoginRouter = [
    '/login',
    '/wechat',
    '/SearchResultPage',
    '/Transfer',
    '/android',
    '/404',
    '/QuestionnaireLink',
    '/IntelligentReportDetailPDF',
    '/IntelligentReportDetailMobile',
    '/SignaturePage',
    '/activityInvitation',
    '/activityInvitation/activeEarnings',
    '/activityInvitation/activeWithdrawal',
    '/liveData/generatePdf'
];

export default () => (
    <Router>
        <Suspense
            fallback={
                <Spin tip='Loading...'>
                    <div style={{ height: 500 }} />
                </Spin>
            }
        >
            <Switch>
                <Route exact path='/' render={() => <Redirect to='/login' />} />
                <Route path='/login' component={Login} />
                <Route exact path='/wechat' component={Share} />
                <Route exact path='/SearchResultPage' component={SearchResultPage} />
                <Route exact path='/Transfer' component={Transfer} />
                <Route exact path='/android' component={Android} />
                <Route exact path='/404' component={Login} />
                <Route exact path='/QuestionnaireLink' component={QuestionnaireLink} />
                <Route exact path='/IntelligentReportDetailPDF' component={IntelligentReportDetailPDF} />
                <Route exact path='/IntelligentReportDetailMobile' component={IntelligentReportDetailMobile} />
                <Route exact path='/SignaturePage' component={SignaturePage} />
                <Route exact path='/activityInvitation' component={ActivityInvitation} />
                <Route exact path='/activityInvitation/activeEarnings' component={ActiveEarnings} />
                <Route exact path='/activityInvitation/activeWithdrawal' component={ActiveWithdrawal} />
                <Route exact path='/liveData/generatePdf' component={PageGeneratePdf} />
                <Route exact path='/AcademicPlan/InvitationLetter' component={InvitationLetter} />
                <Route path='/' component={App} />
            </Switch>
        </Suspense>
    </Router>
);
